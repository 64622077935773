<script lang="ts" setup>
import { AppConfigInput } from 'nuxt/schema'
import p from './../../package.json'
const app = useAppConfig() as AppConfigInput
const currYear = new Date().getFullYear()
const { t } = useLang()
const menus = computed(() => [
  {
    type: 'link',
    text: t('pages.order.title'),
    route: { name: 'dashboard' },
  },
  {
    type: 'link',
    text: t('pages.aboutus.nav'),
    route: { name: 'about-us' },
  },
  {
    type: 'link',
    text: t('pages.products.nav'),
    route: { name: 'our-products' },
  },
  {
    type: 'link',
    text: t('pages.healthAndQuality.nav'),
    route: { name: 'health-and-quality' },
  },
])
</script>
<template>
  <div class="relative pt-42 overflow-hidden">
    <nuxt-img
      provider="s3"
      src="/img_footer_chicken.svg"
      title="Chickens"
      class="w-20 md:w-40 absolute z-10 right-16 top-26 md:top-4"
      data-aos="fade-left"
      data-aos-delay="250"
      data-aos-offset="200"
      loading="lazy"
    />
    <nuxt-img
      provider="s3"
      src="/img_footer_line.svg"
      title="Line"
      class="-mb-2 w-full"
      sizes="100vw"
      width="1470"
      height="67"
      loading="lazy"
      alt="Line"
    />
    <footer class="bg-green relative">
      <div class="container mx-auto pt-8 md:pt-11 xl:pt-6 md:pt-0 pb-2 px-4">
        <div class="grid md:grid-cols-12 gap-4 justify-between">
          <div class="col-span-3 mb-5">
            <nuxt-img
              provider="s3"
              src="/img_white_logo.svg"
              title="Logo Kmetija Grgar"
              class="mb-4 bg-no-repeat w-60"
              sizes="(max-width: 600px) 120px, 240px"
              width="240"
              height="64"
              loading="lazy"
            />
            <div class="text-white text-base">
              Kmetija Grgar<br />
              Grgar 10, 5251 Grgar<br />
              +386 40 392 407<br />
              info@domacipiscanci.si<br />
            </div>
          </div>

          <div
            class="flex flex-wrap gap-12 md:gap-22 col-span-9 md:justify-end"
          >
            <div class="">
              <div class="text-lg font-bold text-white mb-6">
                {{ $t('footer.index') }}
              </div>
              <ul class="grid gap-1">
                <li v-for="(item, i) in menus" :key="i">
                  <Anchor
                    v-if="item.type === 'link'"
                    :to="item.route ? item.route : undefined"
                    :exact-active-class="'font-bold'"
                    class="text-white ease-in-out duration-150 hover:opacity-50 hover:text-white hover:no-underline"
                    >{{ item.text }}</Anchor
                  >
                </li>
              </ul>
            </div>
            <div class="">
              <div class="text-lg font-bold text-white mb-6 mr-20">
                {{ $t('footer.followUs') }}
              </div>
              <div class="flex gap-3">
                <a
                  href="https://www.facebook.com/pravi.domacipiscanci"
                  title="Instagram"
                  class="text-h5w fill-white ease-in-out duration-150 hover:opacity-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"
                    />
                  </svg>
                </a>

                <a
                  href="https://www.facebook.com/pravi.domacipiscanci"
                  title="Facebook"
                  class="text-h5w fill-white ease-in-out duration-150 hover:opacity-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr class="text-white opacity-25 mt-18 mb-4" />
        <div class="text-center">
          <div class="text-white text-xs -mb-1">
            {{ '©' + currYear + ' ' + $t('footer.copyright') }}
          </div>
          <a class="text-[8px] text-white">Oblikovanje in izvedba</a>
          <a
            href="https://klemenc.si"
            target="_blank"
            title="Klemenc"
            class="text-[9px] text-white ease-in-out duration-150 hover:opacity-75"
          >
            Nik Klemenc &
          </a>
          <a
            href="https://www.linkedin.com/in/ziga-pregelj/"
            target="_blank"
            title="linkedin"
            class="text-[9px] text-white ease-in-out duration-150 hover:opacity-75"
            >Žiga Pregelj</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>
<style></style>
