<script lang="ts" setup>
import { AppConfigInput } from 'nuxt/schema'

export interface IMenuItem {
  type: 'link' | 'button'
  text: string
  href?: any
  route?: any
}

const { t } = useLang()
const app = useAppConfig() as AppConfigInput
const menus = computed((): IMenuItem[] => [
  {
    type: 'link',
    text: t('pages.aboutus.nav'),
    route: { name: 'about-us' },
  },
  {
    type: 'link',
    text: t('pages.products.nav'),
    route: { name: 'our-products' },
  },
  {
    type: 'link',
    text: t('pages.healthAndQuality.nav'),
    route: { name: 'health-and-quality' },
  },
  {
    type: 'button',
    text: t('pages.order.nav'),
    route: { name: 'dashboard' },
  },
])
</script>

<template>
  <BuilderNavbar>
    <template #menu>
      <div class="relative hidden lg:flex items-center ml-auto">
        <nav
          class="text-sm leading-6 font-semibold text-gray-600 dark:text-gray-300"
          role="navigation"
        >
          <ul class="flex items-center space-x-0">
            <li v-for="(item, i) in menus" :key="i">
              <Anchor
                v-if="item.type === 'link'"
                :to="item.route ? item.route : undefined"
                :href="item.href ? item.href : undefined"
                class="py-3 px-6 text-base text-black hover:no-underline ease-in-out duration-150 hover:bg-[#45994526] rounded-btn hover:text-green font-bold"
                :exact-active-class="'text-green !font-bold'"
                >{{ item.text }}</Anchor
              >
              <Button
                v-else-if="item.type === 'button'"
                button-name="menu-button"
                class="btn-base fill-green ml-3"
                :to="item.route ? item.route : undefined"
                :href="item.href ? item.href : undefined"
              >
                {{ item.text }}
                <font-awesome-icon
                  :icon="['fas', 'arrow-right']"
                  class="icon-right-anim"
                />
              </Button>
            </li>
          </ul>
        </nav>
        <div class="flex ml-6 border-gray-900/10 dark:border-gray-50/[0.2]">
          <LanguageSwitcher />
        </div>
      </div>
    </template>
    <template #options="{ toggleOptions }">
      <ActionSheet @on-close="toggleOptions(false)">
        <ActionSheetBody>
          <ActionSheetHeader text="Menu" />
          <nav class="leading-6 font-semibold text-gray-600 dark:text-gray-300">
            <ul class="flex flex-col">
              <li
                v-for="(item, i) in menus"
                :key="i"
                class="flex w-full"
                :class="{
                  'text-lg': item.type === 'link',
                }"
              >
                <Anchor
                  v-if="item.type === 'link'"
                  :to="item.route ? item.route : undefined"
                  :href="item.href ? item.href : undefined"
                  class="py-3 px-0 text-base text-black hover:no-underline font-bold w-full"
                  :exact-active-class="'text-green !font-bold'"
                  >{{ item.text }}</Anchor
                >
                <Button
                  v-else-if="item.type === 'button'"
                  class="btn-base fill-green mt-3 w-full"
                  :to="item.route ? item.route : undefined"
                  :href="item.href ? item.href : undefined"
                  button-name="menu-button"
                >
                  {{ item.text }}
                  <font-awesome-icon
                    :icon="['fas', 'arrow-right']"
                    class="icon-right-anim"
                  />
                </Button>
              </li>
            </ul>
          </nav>
          <div class="mt-6 text-sm capitalize italic font-dark-gray">
            {{ $t('components.language_switcher.change_language') }}
          </div>
          <div class="mt-2">
            <LanguageSwitcher type="select-box" />
          </div>
        </ActionSheetBody>
      </ActionSheet>
    </template>
  </BuilderNavbar>
</template>
